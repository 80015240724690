import { useEffect, useState } from "react"
import { ArrowLeftCircle, ArrowRightCircle } from "react-feather"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getPositionOfUsers } from "../../myApiCenter"
import toast from "react-hot-toast"
import { Progress, Spinner } from "reactstrap"

const UserPositionInTable = (props) => {
   // console.log(props)
   const [positions, setpositions] = useState([])
   const [isloading, setisloading] = useState(false)
   const getData = async () => {
      try {
         setisloading(true)
         const j = await getPositionOfUsers(props.data._id)
         // console.log(j.data)
         setpositions(j?.data?.response?.data)
      } catch (error) {
         // console.log(props.data.name)
         // console.log(error)
         console.log("error in position")
      } finally {
         setisloading(false)
      }
   }
   useEffect(() => {
      getData()

      return () => {}
   }, [props.data._id])
   // console.log(positions)
   return (
      <div className="d">
         {isloading ? (
            <Spinner animated className="ms-2" color="info" value={50} />
         ) : (
            <>
               {!isloading && positions?.length > 0 ? (
                  <>
                     {positions?.map((e, index) => {
                        return (
                           <div key={index}>
                              <span>
                                 {e.symbolname}:{e.netqty}
                              </span>
                           </div>
                        )
                     })}
                  </>
               ) : (
                  <>-------</>
               )}
            </>
         )}
      </div>
   )
}

export default UserPositionInTable
