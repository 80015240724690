import MainNav from "../MainNav/MainNav"

const Home = () => {
   return (
      <>
         <MainNav />
         <div className="mt-5">
            <h1>Home</h1>
         </div>
      </>
   )
}

export default Home
