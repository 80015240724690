import { NavLink } from "react-router-dom"
import { AuthContext } from "../../context/Can"
import "./MainNav.css"

import { useContext } from "react"
const MainNav = () => {
   // const token = gettoken()
   const [userData, userDataSetter] = useContext(AuthContext)

   // console.log(userData)
   return (
      <nav role="navigation">
         <div id="menuToggle">
            <input type="checkbox" />
            <span></span>
            <span></span>
            <span></span>
            <ul id="menu">
               <li>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/home"}>
                     HOME
                  </NavLink>
               </li>
               {userData?.login && (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/dashboard"}>
                        DASH BOARD
                     </NavLink>
                  </li>
               )}

               <li>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/about"}>
                     ABOUT US
                  </NavLink>
               </li>
               <li>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/contact"}>
                     CONTACT US
                  </NavLink>
               </li>
               <li>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/privacy"}>
                     Privacy Policy
                  </NavLink>
               </li>
               {(userData?.userRole === "admin" || userData?.userRole === "manager") && (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/unlogged-user"}>
                        UNLOGGED USER
                     </NavLink>
                  </li>
               )}
               {userData?.userRole === "admin" && (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/stocks"}>
                        STOCKS
                     </NavLink>
                  </li>
               )}

               {userData?.userRole === "admin" && (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/users"}>
                        USERS
                     </NavLink>
                  </li>
               )}

               {!userData?.login && (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/signup"}>
                        SIGN UP
                     </NavLink>
                  </li>
               )}

               {userData?.login ? (
                  <li>
                     <NavLink onClick={() => userDataSetter({})} className={({ isActive }) => (isActive ? "active" : "")} to={"/login"}>
                        LOG OUT
                     </NavLink>
                  </li>
               ) : (
                  <li>
                     <NavLink className={({ isActive }) => (isActive ? "active" : "")} to={"/login"}>
                        LOG IN
                     </NavLink>
                  </li>
               )}
            </ul>
         </div>
      </nav>
   )
}

export default MainNav
