import MainNav from "../MainNav/MainNav"

const Privacy = () => {
   return (
      <>
         <MainNav />
         <div className="mt-5 pt-3 privacy">
            <h1>Privacy Policy for DHAN-HIKE</h1>

            <p>
               This Privacy Policy describes how DHAN-HIKE collects, uses, maintains, and discloses information collected from users ("User" or
               "Users") of the DHAN-HIKE website and platform.
            </p>

            <h2>Personal Identification Information:</h2>
            <p>
               DHAN-HIKE may collect personal identification information from Users in various ways, including but not limited to when Users visit the
               website, register on the platform, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other
               activities, services, features, or resources made available on DHAN-HIKE. Users may be asked for their name, email address, mailing
               address, phone number, and other relevant details. Users may, however, visit DHAN-HIKE anonymously. DHAN-HIKE will collect personal
               identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply
               personal identification information, except that it may prevent them from engaging in certain activities related to DHAN-HIKE.
            </p>

            <h2>Non-personal Identification Information:</h2>
            <p>
               DHAN-HIKE may collect non-personal identification information about Users whenever they interact with the website or platform.
               Non-personal identification information may include the browser name, the type of computer, and technical information about Users'
               means of connection to DHAN-HIKE, such as the operating system and the Internet service providers utilized and other similar
               information.
            </p>

            <h2>How We Use Collected Information:</h2>
            <p>DHAN-HIKE may collect and use Users' personal information for the following purposes:</p>
            <ul>
               <li>
                  To improve customer service: Information you provide helps us respond to your customer service requests and support needs more
                  efficiently.
               </li>
               <li>
                  To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and
                  resources provided on our platform.
               </li>
               <li>To improve our platform: We may use feedback you provide to improve our products and services.</li>
               <li>
                  To send periodic emails: We may use the email address to send Users information and updates pertaining to their order or
                  subscription. It may also be used to respond to their inquiries, questions, and/or other requests.
               </li>
            </ul>

            <h2>How We Protect Your Information:</h2>
            <p>
               DHAN-HIKE adopts appropriate data collection, storage, and processing practices and security measures to protect against unauthorized
               access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data
               stored on our platform.
            </p>

            <h2>Sharing Your Personal Information:</h2>
            <p>
               DHAN-HIKE does not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated
               demographic information not linked to any personal identification information regarding visitors and Users with our business partners,
               trusted affiliates, and advertisers for the purposes outlined above.
            </p>

            <h2>Changes to This Privacy Policy:</h2>
            <p>
               DHAN-HIKE has the discretion to update this Privacy Policy at any time. When we do, we will revise the updated date at the bottom of
               this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the
               personal information we collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically
               and become aware of modifications.
            </p>

            <h2>Your Acceptance of These Terms:</h2>
            <p>
               By using DHAN-HIKE, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use
               DHAN-HIKE. Your continued use of DHAN-HIKE following the posting of changes to this policy will be deemed your acceptance of those
               changes.
            </p>

            <h2>Contacting Us:</h2>
            <p>
               If you have any questions about this Privacy Policy, the practices of DHAN-HIKE, or your dealings with DHAN-HIKE, please contact us at
               [Your Contact Information].
            </p>

            <p>This document was last updated on 1/1/24.</p>
         </div>
      </>
   )
}

export default Privacy
