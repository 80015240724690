import MainNav from "../MainNav/MainNav"

const ContactUs = () => {
   return (
      <>
         <MainNav />
         <div className="mt-5">
            <h1>ContactUs</h1>
         </div>
      </>
   )
}

export default ContactUs
